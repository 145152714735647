@use "./libs/shared/styles/app-theme" as *;
@use "./libs/shared/styles/style-guide-typography" as *;
@use "./libs/shared/styles/style-guide-colors" as *;
@use "./libs/shared/styles/mixins" as *;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

:root {
    --ion-background-color: #{$color-gray-50};
    --ion-font-family: #{$typography-font-family};
    --ion-text-color: #{$color-gray-700};
}

.ion-page {
    overflow-y: auto;
    background-color: $color-gray-50;
}

ion-content {
    --overflow: hidden;
}

ion-card {
    @include default-box-shadow;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 20px 16px;
    border-radius: 8px;
    background: $color-white;
}

.logo {
    a {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        object-fit: contain;
        height: 32px;
        max-width: 100%;
    }
}

.map-info-toast-container {
    position: relative;

    .toast-container {
        position: absolute;
        z-index: 1001; // NOTE: Required to overlap map controls
        transform: translate(-50%);
        left: 50%;
        right: unset;
        top: 16px;
    }
}
